<template>
<div>
    <v-card class="border-radius-sm" :class="!$vuetify.theme.dark ? 'bg-upload' : ''" flat>
        <div class="pa-2 d-flex justify-content-between">
            <v-btn :loading="$global.state.uploading" @click="handleFileImport" icon="icon"
                :color="icon ? 'info' : 'primary'" :class="icon ? '' : 'btn secondary--text'">
                <v-icon left>fa-solid fa-paperclip</v-icon>
                <h3 v-if="!icon">{{ buttonText }}</h3>
            </v-btn>
            <v-spacer></v-spacer>
            <small class="red--text mt-2">{{ warnText }}</small>
            <input ref="uploader" class="d-none" type="file" multiple accept="accept" @change="onFileChanged" />
        </div>
        <v-card-text v-show="previews[0]">
            <v-row>
                <v-col cols="12" sm="1" class="ml-5" v-for="(preivew, i) in previews" :key="i">
                    <v-badge offset-x="15" right color="transparent">
                        <template v-slot:badge>
                            <v-btn @click="deleteFile(i)" icon dark x-small class="pink btn-delete-file">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <v-img class="border-radius-sm" height="60" width="60" :src="preivew" />
                        <small v-if="$store.state.itemDetails.dialogType == 'add'">{{ files[0] ?
                            `${(Math.round(+files[i].size / 1024) /1000).toFixed(2)}MB` : 0 }}</small>
                    </v-badge>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["accept", "buttonText", "formData", "endPoint", "icon"],
    data() {
        return {
            warnText: "",
        };
    },

    computed: {
        ...mapState(["files", "previews"]),
    },
    methods: {
        deleteFile(i) {
            this.files.splice(i, 1);
            this.previews.splice(i, 1);
            if (this.$store.state.itemDetails.dialogType == 'edit') {
                this.$store.state.itemDetails.attachments.splice(i, 1);
            }
        },

        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.uploader.click();
        },

        onFileChanged(e) {
            let selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                let file = selectedFiles[i];
                if (file && file["type"].split("/")[0] === "image" || file["type"].split("/")[0] === "video") {
                    this.warnText = "";
                    this.files.push(file);
                    this.previews.push(URL.createObjectURL(file));
                }
                else {
                    this.warnText = "!! الملف غير مدعوم";
                }
            }
        },
    },
};
</script>

<style scoped>
.bg-upload {
    background: #f7f9fc;
}

.parent {
    display: flex;
    flex-wrap: wrap;
}

.child {
    display: inline-block;
    flex: 1 0 10%;
}

.btn-delete-file {
    padding: 10px;
    font-weight: bold;
}
</style>
